@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sriracha&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");

* {
  transition: all 0.3s ease-out;
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-thumb {
  display: none;
}

