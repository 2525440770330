div.ev-headbar {
  z-index: 999;
  background-color: #fbf7f5;
  padding: 4px;
  position: absolute;
  left: 0;
  right: 0;
  top:0;
  p {
    color: black;
    font-size: 0.7rem;
    text-align: center;

    a {
      color: inherit;
      text-decoration: none;
      padding-bottom: 1px;
      border-bottom: 1px dotted black;
    }
  }
}